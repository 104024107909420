<template>
  <div class="flex min-h-screen w-full overflow-hidden bg-gray-50">
    <!-- Sidebar -->
    <div
      class="fixed bottom-0 mt-[1px] flex h-16 w-full bg-white sm:static sm:h-[calc(100vh-1px)] sm:w-24 sm:flex-col md:w-56"
    >
      <NuxtLink
        to="/"
        aria-label="Home link"
        class="underline-nav -mt-[0.075rem] hidden h-16 min-h-16 w-full items-center justify-center gap-x-2 px-3 text-gray-800 sm:flex md:justify-start"
      >
        <Logo class="hidden h-6 w-fit md:flex" />
        <LogoIcon class="h-9 w-fit md:hidden" />
      </NuxtLink>
      <div
        class="flex h-full w-full items-center justify-around gap-x-1 bg-white pl-2 sm:flex-col sm:justify-between sm:gap-x-0 sm:bg-transparent sm:px-3 sm:py-3"
      >
        <div
          class="flex w-full items-center justify-around gap-y-1 sm:flex-col sm:items-start"
        >
          <NavVerticalTabs :tabs="navItems" />
        </div>
        <div class="flex flex-col gap-y-1 sm:w-full">
          <DropDown v-slot="{ open }" :items="dropDownItems">
            <div
              :class="[
                'flex w-full items-center justify-center rounded-xl border border-gray-300 px-2 py-1 shadow-sm transition-all hover:bg-gray-200 hover:bg-opacity-50 md:justify-between',
                open ? 'bg-gray-200 bg-opacity-50' : ''
              ]"
            >
              <div class="flex items-center gap-x-2">
                <Avatar class="h-8 w-8" />
                <span
                  class="hidden truncate text-sm text-gray-600 md:inline-block"
                >
                  {{ auth.user?.firstName }}
                </span>
              </div>
              <Icon
                name="chevron"
                :class="[
                  'mr-1 hidden h-3.5 w-3.5 transition-transform md:flex',
                  open ? 'rotate-180' : ''
                ]"
              />
            </div>
          </DropDown>
        </div>
      </div>
    </div>
    <!-- Main -->
    <div class="h-[calc(100vh-4rem)] w-full flex-1 overflow-y-auto sm:h-screen">
      <NuxtPage />
    </div>
  </div>
</template>

<script setup lang="ts">
const isStageZero = useIsStageZero()

const navItems = computed<NavItem[]>(() => {
  return [
    { title: 'Dashboard', to: '/dashboard', icon: 'dashboard' },
    { title: 'Internal Data', to: '/internal-data', icon: 'folder' },
    { title: 'Studies', to: '/studies', icon: 'rectangle-stack' }
  ]
})

const { auth, logout } = useFetchAuth()

const dropDownItems = computed<NavItem[]>(() => {
  const navItems: NavItem[] = [
    {
      title: 'Settings',
      icon: 'cog',
      to: '/settings/account',
      children: [
        { title: 'Account', icon: 'user', to: '/settings/account' },
        { title: 'Workspace', icon: 'user-group', to: '/settings/workspace' }
      ]
    }
  ]

  if (isStageZero) {
    navItems.push({
      title: 'Company Profile',
      icon: 'identification',
      to: '/company-profile'
    })
  }

  navItems.push({
    title: 'Logout',
    icon: 'logout',
    handler: () => logout()
  })
  return navItems
})
</script>
